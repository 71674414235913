import type { GetStaticPaths, GetStaticProps } from 'next';

import {
  PageHead,
  DynamicComponents,
  ComponentMarketoBlock,
  ComponentNewsletterSignup,
  HeroClipSVG,
} from '@/ui/';

import { Schema } from '@/ui/base/Schema';
import { useScriptField } from '@/ui/hooks';

import {
  getStandardPageStaticPaths,
  getStandardPageStaticProps,
} from '@/intergrations';

import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { InsightLatest } from '@/types';

export const getStaticPaths: GetStaticPaths = async (context) => {
  return await getStandardPageStaticPaths(context);
};

export const getStaticProps: GetStaticProps = async (context) => {
  return await getStandardPageStaticProps(context);
};

type LandingPageProps = Readonly<{
  pageData: any;
  newsletterBlock: any;
  latestInsightsCards?: {
    largeCard: InsightLatest;
    smallCards: Array<InsightLatest>;
  };
}>;

export default function StandardPage({ pageData, newsletterBlock, latestInsightsCards }: LandingPageProps) {

  const updatedPageData = useContentfulLiveUpdates(pageData);
  const updatedNewsletterBlock = useContentfulLiveUpdates(newsletterBlock);

  const {
    components = [],
    pageHeading,
    pageTag,
    insightTypeReference,
    publishDate,
    writtenBy,
    showNewsletterSignup,
    scriptField,
  } = updatedPageData;

  const articleAnchors = components
  .filter((component: { __typename: string; anchorPointId: string; anchorLinkTitle: string }) => component.__typename === 'DataArticleAnchor')
  .map((component: { anchorPointId: string; anchorLinkTitle: string }) => ({
    id: component.anchorPointId,
    title: component.anchorLinkTitle,
  }));

  const additionalData = {
    title: pageHeading,
    tags: pageTag,
    insightTypes: insightTypeReference,
    publishDate,
    writtenBy,
    anchorLinks: articleAnchors,
    latestInsightsCards
  };

  const renderArticleNewsletterBlock = () => {
    // If the page is not an article, or the showNewsletterSignup is false, don't render the newsletter block
    if (!insightTypeReference || showNewsletterSignup === false) {
      return null;
    }

    const marketoBlock = updatedNewsletterBlock.entry?.[0];
    // If there is no 'globalNewsletterComponentId' set in Configuration Site Settings in Contentful, don't render the newsletter block
    if (!marketoBlock) {
      return null;
    }

    // If the Marketo Data Capture is set to Subscribe, render the newsletter signup component, otherwise render the marketo enquiry component
    return marketoBlock.marketoType === 'Subscribe' ? (
      <ComponentNewsletterSignup {...marketoBlock} />
    ) : (
      <ComponentMarketoBlock {...marketoBlock} />
    );
  };

  const articleNewsletterBlock = renderArticleNewsletterBlock();

  // Check if the page has a hero or banner carousel component, if component is present set to return true.
  // We are setting to true, so that we can only render HeroClipSVG on pages that have a hero or banner carousel component.
  const componentsWithHeroClip = components.map((component: { componentType: string; }) => {
    if (
      component.componentType === 'ComponentHero' ||
      component.componentType === 'ComponentBannerCarousel' ||
      component.componentType === 'ComponentHeroCarousel'
    ) {
      return true;
    }    
  });

  return (
    <>
      <PageHead pageData={updatedPageData} />
      <Schema pageData={updatedPageData} />
      <DynamicComponents components={components} data={additionalData} />
      {articleNewsletterBlock}
      {componentsWithHeroClip && <HeroClipSVG />}
      {useScriptField(scriptField)}
    </>
  );
}
